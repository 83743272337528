<template>
    <main>
      <canvas id="imcNinos5a19A" class="canvas-h canvas-w"></canvas>
    </main>
  </template>
  
  <script>
  import Chart from 'chart.js/auto';
  import chartData from '../../../../../assets/datasheets/charts/imcNinos5a19A.json';
  
  export default {
    props : ['x','y'],
    methods : {
      open(){
        console.log('edad',this.x);
        const labels = chartData.datapoints;
        const datasets = [];
  
        chartData.series.forEach(s => {
          datasets.push({
            type : 'line',
            label: s.title,
            data: s.data,
            borderColor: s.color,
            fill: false,
            tension: 0.5,
            borderWidth : 1.5,
          });
        });
  
        datasets.push({
          type: 'scatter',
          backgroundColor: 'rgba(75, 00, 150,1)',
          borderColor: 'rgba(75, 00, 150,1)',
          label : 'IMC/Edad',
          data: [{
            "x" : Math.floor(this.x),
            "y" : this.y
          }],
          elements : {
            point : {
              radius : 4
            }
          }
        });
  
        const data = {
          labels: labels,
          datasets: datasets
        };
  
        const config = {
          data: data,
          options: {
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: 'IMC Niños de 5 a 19 Años'
              },
            },
            interaction: {
              intersect: false,
            },
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                  text: 'Edad En Años'
                },
                ticks: {
                    min: 60, // Iniciar en 5 años (60 meses)
                    max: 228, 
                    callback: function (value) {
                    if(Number(this.getLabelForValue(value)) % 12 === 0){
                        return `${Number(this.getLabelForValue(value))/12} A`;
                    }
                    },
                    stepSize: 1, // Ajustar el paso según sea necesario
                    // Terminar en 19 años (228 meses)
                },
              },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: "Imc",
                    },
                    ticks: {
                        stepSize: 2, // Intervalos de 10 cm
                        min: 12, // Altura mínima
                        max: 36, // Altura máxima
                    },
                },
            },
            elements : {
              point : {
                radius : 0
              }
            }
          },
        };
  
        const ctx = document.getElementById('imcNinos5a19A').getContext('2d');
  
        new Chart(ctx, config);
      },
      export(){
        const canva = document.getElementById('imcNinos5a19A');
        const urlData = canva.toDataURL('image/png', 0.8);
        return {
          chart : 'IMC Niños 5 a 19 Años',
          imageData : urlData,
          observaciones:'',
          codigo:'imcNinos5a19A'
        };
      }
    },
    created() {
      this.$nextTick(() => this.open());
    }
  }
  
  </script>
  
  <style scoped>
    .canvas-h{
      height: 100%;
    }
    .canvas-w{
      width: 100%;
    }
  </style>