import { render, staticRenderFns } from "./pesoParaLaEdadNinas5a10A.vue?vue&type=template&id=6555e358&scoped=true&"
import script from "./pesoParaLaEdadNinas5a10A.vue?vue&type=script&lang=js&"
export * from "./pesoParaLaEdadNinas5a10A.vue?vue&type=script&lang=js&"
import style0 from "./pesoParaLaEdadNinas5a10A.vue?vue&type=style&index=0&id=6555e358&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6555e358",
  null
  
)

export default component.exports